<template>
	<index-success-stories />
</template>

<script>
import IndexSuccessStories from '@/components/SuccessStories/Index';

export default {
	name: 'SuccessStories',
	components: {
		IndexSuccessStories
	}
}
</script>

<style scoped></style>
