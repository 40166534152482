<template>
  <index-libraries />
</template>

<script>
import IndexLibraries from '@/components/Libraries/Index';

export default {
  name: "Libraries",
  components: {
    IndexLibraries
  }
}
</script>

<style scoped>

</style>