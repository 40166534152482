<template>
  <index-resume />
</template>

<script>
import IndexResume from '@/components/Resume/Index';

export default {
  name: "Resume",
  components: {
    IndexResume
  }
}
</script>

<style scoped>

</style>